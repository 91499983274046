import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`allowHTML`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`content`}</code>{` strings are parsed as HTML instead of text.`}</strong>{` `}<br /><br />{` Make sure you are sanitizing any user data if rendering HTML to prevent XSS attacks.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><code parentName="strong" {...{
                "className": "language-text"
              }}>{`animateFill`}</code></strong>{` (available as a `}<a parentName="td" {...{
              "href": "/plugins/"
            }}>{`plugin`}</a>{`)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the background fill color of the tippy should be animated.`}</strong>{` `}<br /><br />{` You must import the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`dist/backdrop.css`}</code>{` & `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`animations/shift-away.css`}</code>{` stylesheets for styling to work.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`animation`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`"fade"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`The type of transition animation.`}</strong>{` `}<br /><br />{` See `}<a parentName="td" {...{
              "href": "/animations/"
            }}>{`Animations`}</a>{` for details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`appendTo`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`document.body`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`The element to append the tippy to.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"parent"`}</code>{` (the reference's parentNode), `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`Element`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`(reference) => Element`}</code>{`. `}<br /><br />{` If `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`interactive: true`}</code>{`, the default behavior is `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`appendTo: "parent"`}</code>{` instead of `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`document.body`}</code>{`. `}<br /><br />{` See `}<a parentName="td" {...{
              "href": "/accessibility/#interactivity"
            }}>{`Accessibility`}</a>{` for more information.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`aria`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`"describedby"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`The `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`aria-*`}</code>{` attribute applied to the reference element.`}</strong>{` This allows screen reader software to announce the tippy content once the reference element is in focus. `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"describedby"`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"labelledby"`}</code>{`. Use `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`null`}</code>{` to prevent the attribute from being added.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`arrow`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the tippy has an arrow.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`boolean`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`string`}</code>{` (of SVG), or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`SVGElement`}</code>{`. `}<br /><br />{` To use the default round arrow, import `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`roundArrow`}</code>{` from the package (`}<code parentName="td" {...{
              "className": "language-text"
            }}>{`tippy.roundArrow`}</code>{` in `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`iife`}</code>{` version) and pass it as the value. `}<br /><br />{` You must also import `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`dist/svg-arrow.css`}</code>{` when using SVG arrows for styling to work.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`boundary`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`"scrollParent"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`The boundary that Popper.js' `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`preventOverflow`}</code>{` modifier adheres to.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"scrollParent"`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"window"`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"viewport"`}</code>{`, or an `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`HTMLElement`}</code>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`content`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`""`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`The content of the tippy.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`string`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`Element`}</code>{`, or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`(reference) => Element`}</code>{`. `}<br /><br />{` Set `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`allowHTML: false`}</code>{` to render strings as text instead of HTML.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`delay`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`0`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Delay in ms once a trigger event is fired before a tippy shows or hides.`}</strong>{` `}<br /><br />{` Possibles values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`number`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`[number, number]`}</code>{` = `}{`[show, hide]`}{`. `}<br /><br />{` Specifying a number means both the show and hide delays are the same. Use `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`null`}</code>{` in the array to use the default value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`distance`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`10`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`How far in pixels the tippy element is from the reference element.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`number`}</code>{` (px), `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`string`}</code>{` (with units `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"rem"`}</code>{` only). `}<br /><br />{` Only applies to a single axis and not to the parent popper element, see the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`offset`}</code>{` prop.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`duration`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`[275, 250]`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Duration of the CSS transition animation in ms.`}</strong>{` `}<br /><br />{` Possibles values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`number`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`[number, number]`}</code>{` = `}{`[show, hide]`}{`. `}<br /><br />{` Specifying a number means both the show and hide delays are the same. Use `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`null`}</code>{` in the array to use the default value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`flip`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the tippy flips so that it is placed within the viewport as best it can be if there is not enough space.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`flipBehavior`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`"flip"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines the order of flipping, i.e. which placements to prefer if a certain placement cannot be used.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"flip"`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`Placement[]`}</code>{`. `}<br /><br />{` Use an array such as `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`["bottom", "left"]`}</code>{` to prefer the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"left"`}</code>{` placement if `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"bottom"`}</code>{` is unavailable, instead of `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"top"`}</code>{`, if using the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"bottom"`}</code>{` placement.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`flipOnUpdate`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the tippy should flip when necessary if its position updates while showing (for example, while scrolling, resize, or if the size of the tooltip changed).`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><code parentName="strong" {...{
                "className": "language-text"
              }}>{`followCursor`}</code></strong>{` (available as a `}<a parentName="td" {...{
              "href": "/plugins/"
            }}>{`plugin`}</a>{`)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the tippy follows the user's mouse cursor.`}</strong>{` `}<br /><br />{` Use the strings `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"vertical"`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"horizontal"`}</code>{` to only follow the cursor on a single axis. `}<br /><br />{` Use `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"initial"`}</code>{` to place the tippy at the initial cursor position upon show, but prevent following it. `}<br /><br />{` On touch devices, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"initial"`}</code>{` is the behavior for all truthy values.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`hideOnClick`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the tippy should hide if a mousedown event was fired outside of it (i.e. clicking on the reference element or the body of the page).`}</strong>{` `}<br /><br />{` For click-triggered tippies, using `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code>{` will prevent the tippy from ever hiding once it is showing. `}<br /><br />{` To prevent clicks outside of the tippy from hiding it but still allow it to be toggled, use the string `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"toggle"`}</code>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`ignoreAttributes`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`data-tippy-*`}</code>{` attributes on the reference element should be ignored.`}</strong>{` `}<br /><br />{` Increases performance if you enable it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`inertia`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if an inertial slingshot effect is applied to the CSS animation.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><code parentName="strong" {...{
                "className": "language-text"
              }}>{`inlinePositioning`}</code></strong>{` (available as a `}<a parentName="td" {...{
              "href": "/plugins/"
            }}>{`plugin`}</a>{`)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Adds enhanced support for inline element positioning (`}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`display: inline`}</code>{`).`}</strong>{` `}<br /><br />{` Automatically detects inline elements and positions the tippy correctly.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`interactive`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the tippy is interactive, i.e. it can be hovered over or clicked without hiding.`}</strong>{` `}<br /><br />{` If your tippy appears cut off or invisible, `}<a parentName="td" {...{
              "href": "https://atomiks.github.io/tippyjs/faq/#my-tooltip-appears-cut-off-or-is-not-showing-at-all"
            }}>{`see the FAQ`}</a>{` for solutions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`interactiveBorder`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines the size in pixels of the invisible border around a tippy which will prevent it from hiding if the cursor left it.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`interactiveDebounce`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`0`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Number in ms to debounce the internal `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`onMouseMove`}</code>{` handler which determines when an interactive tippy should hide.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`lazy`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the positioning engine (powered by Popper.js) is created lazily. That is, it's only created when necessary upon showing the tippy for the first time.`}</strong>{` `}<br /><br />{` If you need to access the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`popperInstance`}</code>{` synchronously after creation, set this to `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code>{`. Note that disabling this decreases performance considerably.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`maxWidth`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`350`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines the maximum width of the tippy.`}</strong>{` `}<br /><br />{` Use a `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`number`}</code>{` for pixels, or a `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`string`}</code>{` to add units such as `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`rem`}</code>{`, or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"none"`}</code>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`multiple`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the reference element can have multiple tippies applied to it.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`offset`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`0`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines the offset of the tippy element.`}</strong>{` `}<br />{` `}<br />{` Unlike `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`distance`}</code>{`, it can work on both axes by using a string in the form `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"x, y"`}</code>{`, such as `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"50, 20"`}</code>{`. `}<br /><br />{` Avoid using an `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`offset`}</code>{` along the same axis as the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`placement`}</code>{` prop if using `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`interactive: true`}</code>{`. If using a `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`number`}</code>{`, there won't be any problems.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onAfterUpdate`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#onafterupdate"
              }}>{`Lifecycle hook`}</a>{` invoked after the tippy's props have been updated.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onBeforeUpdate`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#onbeforeupdate"
              }}>{`Lifecycle hook`}</a>{` invoked before the tippy's props have been updated.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onCreate`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#oncreate"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy has has been created.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onDestroy`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#ondestroy"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy has has been destroyed.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onHidden`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#onhidden"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy has fully transitioned out and is unmounted from the DOM.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onHide`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#onhide"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy begins to transition out.`}</strong>{` `}<br /><br />{` You can cancel hiding by returning `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code>{` from this lifecycle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onMount`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#onmount"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy has been mounted to the DOM (called after `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`onShow`}</code>{`).`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onShow`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#onshow"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy begins to transition in.`}</strong>{` `}<br /><br />{` You can cancel showing by returning `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code>{` from this lifecycle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onShown`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#onshown"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy has fully transitioned in.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onTrigger`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#ontrigger"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy was triggered by a real DOM event (called before `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`onShow`}</code>{`) to show the tippy.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`onUntrigger`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`noop`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "/lifecycle-hooks/#onuntrigger"
              }}>{`Lifecycle hook`}</a>{` invoked when the tippy was triggered by a real DOM event (called before `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`onHide`}</code>{`) to hide the tippy.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`placement`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`"top"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Positions the tippy relative to its reference element.`}</strong>{` `}<br /><br />{` Use the suffix `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`-start`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`-end`}</code>{` to shift the tippy to the start or end of the reference element, instead of centering it. For example, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"top-start"`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"left-end"`}</code>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`plugins`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`[]`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Specify plugins to use.`}</strong>{` `}<br /><br />{` See `}<a parentName="td" {...{
              "href": "/plugins/"
            }}>{`Plugins`}</a>{` for details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`popperOptions`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`{}`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Specify custom Popper.js options.`}</strong>{` `}<br /><br />{` See the `}<a parentName="td" {...{
              "href": "https://popper.js.org/popper-documentation.html"
            }}>{`Popper.js documentation`}</a>{` for more.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`role`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`"tooltip"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Specifies the `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`role`}</code>{` attribute on the tippy element.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`showOnCreate`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the tippy will be shown immediately once the instance is created (respecting `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`delay`}</code>{`).`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><code parentName="strong" {...{
                "className": "language-text"
              }}>{`sticky`}</code></strong>{` (available as a `}<a parentName="td" {...{
              "href": "/plugins/"
            }}>{`plugin`}</a>{`)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Ensures the tippy stays stuck to its reference element if it moves around or changes size while showing.`}</strong>{` `}<br /><br />{` Use `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`true`}</code>{` to check both `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`reference`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`popper`}</code>{` DOM rects, or use the strings `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"reference"`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"popper"`}</code>{` to check only one rect for improved performance (both are checked by default). `}<br /><br />{` See the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`updateDuration`}</code>{` prop to change the transition duration between position updates.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`theme`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`""`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Themes added as classes (each separated by a space) to the tippy element's `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`classList`}</code>{`.`}</strong>{` `}<br /><br />{` See `}<a parentName="td" {...{
              "href": "/themes/"
            }}>{`Themes`}</a>{` for details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`touch`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines if the tippy displays if the user is currently using touch input.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`boolean`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"hold"`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`["hold", number]`}</code>{`. `}<br /><br />{` Use `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"hold"`}</code>{` to use `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`touch`}</code>{` listeners instead, and e.g. `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`["hold", 500]`}</code>{` to simulate "long press" behavior.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`trigger`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`"mouseenter focus"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`The events (each separated by a space) which cause a tippy to show.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"mouseenter"`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"focus"`}</code>{`,  `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"focusin"`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"click"`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"manual"`}</code>{`. `}<br /><br />{` Use `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`"manual"`}</code>{` to only trigger the tippy programmatically.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`triggerTarget`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`null`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Which element(s) the `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`trigger`}</code>{` event listeners are applied to instead of the reference element.`}</strong>{` `}<br /><br />{` Possible values: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`null`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`Element`}</code>{`, or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`Element[]`}</code>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`updateDuration`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`0`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`The transition duration between position updates of the popper element.`}</strong>{` `}<br /><br />{` Useful for the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`sticky`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`flipOnUpdate`}</code>{` props.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`zIndex`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`9999`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Determines the `}<code parentName="strong" {...{
                "className": "language-text"
              }}>{`z-index`}</code>{` of the tippy.`}</strong></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      